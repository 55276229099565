$("#showPass").change(function () {
    togglePasswordShow(this, $("#Password"));
});

$("#showConfirmPass").change(function () {
    togglePasswordShow(this, $("#PasswordConfirmation"));
});

$("#showCurrentPass").change(function () {
    togglePasswordShow(this, $("#CurrentPassword"));
});

function togglePasswordShow(toggleElem, $PasswordElem) {
    var checked = $(toggleElem).is(":checked");

    if (checked) {
        $PasswordElem.attr("type", "text");
    } else {
        $PasswordElem.attr("type", "password");
    }
}